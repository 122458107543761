const isRelativeUrl = (url: string): boolean => {
	return url.startsWith('/') && !url.startsWith('//');
};

export const checkSameHostUrl = (host: string, url: string): boolean => {
	if (isRelativeUrl(url)) {
		return true;
	}

	try {
		const urlHost = new URL(url).host;
		return urlHost === host;
	} catch (error) {
		console.error('Invalid URL provided:', error);
		return false;
	}
};
