import { SxProps, Theme } from '@mui/material';

import { optimizeImageUrl } from '@/utils/images/imageOptimization';

const sizeDefaultStyles = {
	overflow: 'hidden',
	minWidth: '100%',
	minHeight: '100%',
	height: '100%',
	width: '100%',
};

export const getBackgroundImageStyles = (imagePosition: string, url: string): SxProps<Theme> => ({
	backgroundImage: `url(${optimizeImageUrl(url)})`,
	backgroundPosition: imagePosition,
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	...sizeDefaultStyles,
});

export const getImageStyles = (imagePosition: string): SxProps<Theme> => ({
	objectFit: 'cover',
	objectPosition: imagePosition,
	...sizeDefaultStyles,
});
