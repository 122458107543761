import { HTMLAttributeAnchorTarget, useEffect, useState } from 'react';

import TAGButton from '@/components/TAGButton';
import { clickContext } from '@/utils';
import { Maybe } from '@/types';
import { useAppContext } from '@/context';

import { scheduleButtonStyles } from './SchedulingButton.styles';
import { checkSameHostUrl } from './SchedulingButton.helpers';

interface IProps {
	schedulingButtonUrlMobile?: Maybe<string>;
	schedulingButtonTextMobile?: Maybe<string>;
	schedulingButtonUrl?: Maybe<string>;
	schedulingButtonText?: Maybe<string>;
	isMobile?: boolean;
}

export default function SchedulingButton({
	schedulingButtonUrlMobile,
	schedulingButtonUrl,
	schedulingButtonTextMobile,
	schedulingButtonText,
	isMobile,
}: Readonly<IProps>) {
	const { config } = useAppContext();

	const url = schedulingButtonUrl || '/schedule-an-appointment';
	const label = schedulingButtonText || 'Schedule appointment';
	const resolvedMobileUrl = isMobile && schedulingButtonUrlMobile ? schedulingButtonUrlMobile : url;
	const resolvedMobileLabel = isMobile && schedulingButtonTextMobile ? schedulingButtonTextMobile : label;
	const [buttonTarget, setButtonTarget] = useState<HTMLAttributeAnchorTarget>('_self');

	useEffect(() => {
		const host = globalThis.location?.host ?? '';
		const isSameHost = checkSameHostUrl(host, resolvedMobileUrl);
		if (!isSameHost) {
			setButtonTarget('_blank');
		}
	}, [resolvedMobileUrl]);

	return (
		<TAGButton
			href={resolvedMobileUrl}
			size={isMobile ? 'XS' : 'S'}
			variant="primaryAccent1"
			sx={scheduleButtonStyles}
			dataTestId="button_schedule_desktop"
			analyticsContext={clickContext({ brand: config.name, type: 'scheduleClick' })}
			target={buttonTarget}
		>
			{resolvedMobileLabel}
		</TAGButton>
	);
}
