import { FocalPointImage } from '@/types/generated';

import { FocalPointData } from './FocalPointImage';

/**
 * Rounds a number to a specified precision
 * @see https://stackoverflow.com/a/72906124
 * @param {number} number
 * @param {number} precision
 * @return {*}
 */
function roundNumber(number: number, precision: number) {
	const places = 10 ** precision;
	const res = Math.round(number * places) / places;
	return res;
}

/**
 * Returns the background position value for the image based on the focal point and image data
 *
 * @export
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @return {*}
 */
export function calculatePosition(x: number, y: number, width: number, height: number) {
	return `${roundNumber((x / width) * 100, 4)}% ${roundNumber((y / height) * 100, 4)}%`;
}

/**
 * Resolves the focal point position for the image. If the focal point is not set, it will return 'center center'
 *
 * @export
 * @param {TFocalPointImage['focalPoint']} focalPoint
 * @param {TFocalPointImage['image']} image
 * @return {*}  {string}
 */
export function resolveImagePosition(
	focalPoint?: FocalPointImage['focalPoint'],
	image?: FocalPointImage['image']
): string {
	const focalPointDetails = (focalPoint as FocalPointData)?.focalPoint || (focalPoint as FocalPointData);

	return focalPointDetails && image?.width && image?.height
		? calculatePosition(focalPointDetails.x, focalPointDetails.y, image.width, image.height)
		: 'center center';
}

export function resolveNextImageProps(
	isBackgroundImage: boolean,
	image: NonNullable<FocalPointImage['image']>,
	imageElement: unknown
) {
	if (!imageElement) return {};

	const commonProps = {
		// can't use blur for dynamic images
		// placeholder: 'blur',
		quality: 100,
	};
	// refer to https://nextjs.org/docs/messages/next-image-upgrade-to-13
	// objectFit and layout are not supported for background images
	return isBackgroundImage
		? { ...commonProps, layout: 'fill', objectFit: 'cover', priority: true }
		: {
				...commonProps,
				width: image.width || 0,
				height: image.height || 0,
		  };
}
