import { Box, Menu, PopoverProps, IconButton } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon/TAGSvgIcon';

import { closeButtonStyles } from './MobileMenu.styles';

function getAnchorElHeight(anchorEl: NonNullable<IMobileMenu['anchorEl']>) {
	const marginTop = 10;
	return anchorEl.getBoundingClientRect().height + marginTop;
}

export interface IMobileMenu {
	testIdSuffix?: string;
	open: PopoverProps['open'];
	onClose: PopoverProps['onClose'];
	children: React.ReactNode;
	transitionDuration?: PopoverProps['transitionDuration'];
	anchorEl?: (HTMLDivElement & { getBoundingClientRect: () => { height: number } }) | null;
	isFullScreenMenu?: boolean;
	isWithNavMenu?: boolean;
}

export default function MobileMenu({
	testIdSuffix,
	open,
	onClose,
	children,
	transitionDuration,
	anchorEl,
	isFullScreenMenu,
	isWithNavMenu = false,
}: IMobileMenu) {
	const anchorElHeight = (anchorEl && getAnchorElHeight(anchorEl)) || 0;
	const handleMenuClose = (e: React.SyntheticEvent) => {
		onClose?.(e, 'escapeKeyDown');
	};
	return (
		<>
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={onClose}
				marginThreshold={0}
				transitionDuration={transitionDuration}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={{
					sx: {
						width: '100%',
						maxWidth: '100%',
						// !important to force the Paper to keep the correct distance from the top of the screen
						top: isFullScreenMenu ? 'none' : `${anchorElHeight}px !important`,
						maxHeight: isFullScreenMenu ? '100vh' : `calc(100vh - ${anchorElHeight}px)`,
						height: '100%',
						borderRadius: 0,
						boxShadow: 'none',
					},
				}}
				data-test-id={`section_mobile_menu_${testIdSuffix || ''}`}
			>
				{isFullScreenMenu && (
					<Box sx={closeButtonStyles(isWithNavMenu)}>
						<IconButton onClick={handleMenuClose}>
							<TAGSvgIcon icon="Times" size={28} />
						</IconButton>
					</Box>
				)}
				{children}
			</Menu>
		</>
	);
}
