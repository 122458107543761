import { Grid } from '@mui/material';

import { HeaderNavMenu } from '@/types/generated';

import NavigationMenuItem from './NavigationMenuItem/NavigationMenuItem';
import { navMenuContainerStyles } from './NavigationMenuItem/NavigationMenuItem.styles';

interface INavigationMenu {
	mainNavItems?: HeaderNavMenu['headerMainNavLinksCollection'];
	isSmallScreen?: boolean;
	onClose?: () => void;
}

export default function NavigationMenu({ mainNavItems, isSmallScreen = false }: INavigationMenu) {
	return (
		<Grid
			container
			direction={isSmallScreen ? 'column' : 'row'}
			justifyContent="space-between"
			sx={navMenuContainerStyles(isSmallScreen)}
			data-test-id="header-nav-menu-container"
		>
			{mainNavItems?.items?.map((item, index) =>
				item && item?.headerMainNavLink ? (
					<NavigationMenuItem
						key={index}
						mainNavItemLink={item.headerMainNavLink}
						subNavMenu={item.headerSubNavMenu}
						index={index}
						totalMenuItems={mainNavItems?.items?.length}
					/>
				) : null
			)}
		</Grid>
	);
}
