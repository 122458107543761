import { Asset, Maybe } from '@/types/generated';
import { incorrectUsageWarning } from '@/utils/miscUtils';

import ImageWrapper from '../ImageWrapper';
import { ImgProps } from '../ImageAsset/ImageAsset';

import { getBackgroundImageStyles, getImageStyles } from './FocalPointImage.styles';
import { resolveImagePosition, resolveNextImageProps } from './FocalPointImage.helpers';

import type { SxProps } from '@mui/material';

export type FocalPointData = {
	focalPoint: {
		x: number;
		y: number;
	};
};

export interface BrandFolderImage extends Asset {
	cdn_url?: string;
	appendWebpToUrl?: boolean;
}

interface IFocalPointImage extends ImgProps {
	/**
	 * The image to display.
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IFocalPointImage
	 */
	image?: Maybe<Asset>;
	/**
	 * The image to display.
	 *
	 * @type {Maybe<BrandFolderImage>}
	 * @memberof IFocalPointImage
	 */
	brandFolderImage?: Maybe<BrandFolderImage>;
	/**
	 * The focal point information for the image.
	 *
	 * @type {Maybe<FocalPointData>}
	 * @memberof IFocalPointImage
	 */
	focalPoint?: Maybe<FocalPointData>;
	/**
	 * Whether to display the image as a background image.
	 *
	 * @type {boolean}
	 * @memberof IFocalPointImage
	 * @todo rename this to isBackgroundImage
	 */
	backgroundImage?: boolean;
	/**
	 * Optional styles to apply to the image.
	 *
	 * @type {SxProps}
	 * @memberof IFocalPointImage
	 */
	imageSx?: SxProps;
	/**
	 * Image element to be used to display the image. Defaults to `img`.
	 * This allows us to provide custom, framework specific, image wrapper, such as `next/image` or GatsbyImage.
	 *
	 * @type {unknown}
	 * @memberof IFocalPointImage
	 */
	imageElement?: unknown;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IFocalPointImage
	 */
	dataTestId?: string;
}

// TODO: check if this is working the way we expect it to
export default function FocalPointImage({
	backgroundImage: isBackgroundImage,
	focalPoint,
	image,
	brandFolderImage,
	imageSx,
	imageElement,
	dataTestId,
	children,
	...rest
}: Readonly<IFocalPointImage>) {
	const imageToDisplay = brandFolderImage || image;
	const imageUrl = brandFolderImage ? brandFolderImage.cdn_url : image?.url;
	if (!imageToDisplay || !imageUrl) {
		incorrectUsageWarning('FocalPointImage', 'Image is required.');
		return null;
	}
	const imagePosition = resolveImagePosition(focalPoint, imageToDisplay);
	const styles = isBackgroundImage
		? getBackgroundImageStyles(imagePosition, imageUrl || '')
		: getImageStyles(imagePosition);
	const nextImageProps = resolveNextImageProps(!!isBackgroundImage, imageToDisplay, imageElement);

	return (
		<ImageWrapper
			{...(imageElement ? { style: { styles, ...imageSx } } : { sx: [styles, imageSx].flatMap((v) => v) })}
			src={!isBackgroundImage ? imageUrl : undefined}
			alt={!isBackgroundImage ? imageToDisplay.description || '' : ''}
			imageEl={imageElement as React.ComponentProps<typeof ImageWrapper>['imageEl'] | undefined}
			data-test-id={
				dataTestId ?? `img_${isBackgroundImage ? 'background_' : ''}focal_point_${imageToDisplay?.sys?.id}`
			}
			{...nextImageProps}
			{...rest}
		>
			{isBackgroundImage ? children : null}
		</ImageWrapper>
	);
}
